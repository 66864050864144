import axios from 'axios';

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async createCall({}, post) {
    await axios.post('twitter/post', post);
  }
};

export default {
  actions
};