<template>
  <section>
    <form class="m-auto col-sm-6 col-11" @submit.prevent="submit">
        <div class="mb-4 row">
            <label class="form-label col-3 col-sm-2" for="date">
                Date
            </label>
              <VueDatePicker v-model="form.date" class="col"></VueDatePicker>
        </div>
        <div class="mb-4 row">
            <label class="mb-2 col-3 col-sm-2" for="road">
                Road
            </label>
            <input class="form-control col"
                   id="road" name="road" v-model="form.road" type="text">
        </div>
        <div class="mb-4 row">
            <label class="mb-2 col-3 col-sm-2" for="city">
                City
            </label>
            <input class="form-control col"
                   id="city" name="city" v-model="form.city" type="text">
        </div>

        <div class="mb-4 row">
            <label class="mb-2 col-3 col-sm-2" for="call_type">
                Call Type
            </label>
            <select class="form-control col"
                   id="call_type" name="call_type" v-model="form.call_type">
              <option value="Alarms">Alarms</option>
              <option value="Medical Assistance">Medical Assistance</option>
              <option value="Motor Vehicle Collision">MVC</option>
              <option value="Outside Fire">Outside Fire</option>
              <option value="Investigation">Investigation</option>
              <option value="structure fire">Structure Fire</option>
              <option value="CITIZEN ASSIST/SERVICE CALL">Assist/Service Call</option>
              <option value="Hazmat">Hazmat</option>
              <option value="Technical Rescue">Technical Rescue</option>
              <option value="ELECTRICAL HAZARD">Electrical Hazard</option>
              <option value="AIRCRAFT EMERGENCY">Aircraft Emergency</option>
              <option value="extraction/entrapped">Extraction/Entrapped</option>
              <option value="mutual aid">Mutual Aid</option>

              <option value="Test">Test</option>
            </select>
        </div>


<!--        <div class="row mb-4">-->
<!--            <label class="col-2 mb-2">-->
<!--                Department-->
<!--            </label>-->
<!--            <div class="col">-->
<!--              <div class="row">-->
<!--                <div class="form-check">-->
<!--                  <input name="department" v-model="form.department" id="department1" type="checkbox" value="CFD" class="form-check-input">-->
<!--                  <label for="department1" class="form-check-label text-start w-100">CFD</label>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-check w-auto">-->
<!--                  <input name="department" v-model="form.department" id="department2" type="checkbox" value="CRFD" class="form-check-input">-->
<!--                  <label for="department2" class="form-check-label text-start w-100">CRFD</label>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-check w-auto">-->
<!--                    <input name="department" v-model="form.department" id="department3" type="checkbox" value="NRFD" class="form-check-input">-->
<!--                    <label for="department3" class="form-check-label text-start w-100">NRFD</label>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-check w-auto">-->
<!--                    <input name="department" v-model="form.department" id="department4" type="checkbox" value="NSFD" class="form-check-input">-->
<!--                    <label for="department4" class="form-check-label text-start w-100">NSFD</label>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-check w-auto">-->
<!--                    <input name="department" v-model="form.department" id="department5" type="checkbox" value="test" class="form-check-input">-->
<!--                    <label for="department5" class="form-check-label text-start w-100">Test</label>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="row mb-4">
            <label class="col-3 col-sm-2 mb-2">
                Department
            </label>
            <div class="col">
              <div class="row border-bottom border-1" v-for="department in departments" v-bind:key="department.id">
                <div class="col-3 col-sm-2">
                  <div class="form-check">
                    <input name="department" v-model="checkedDepartments[department.id]" id="department{{department.id}}" type="checkbox" value="{{department.shortName}}" class="form-check-input">
                    <label for="department{{department.id}}" class="form-check-label text-start w-100">{{ department.shortName }}</label>
                  </div>
                </div>
                <div class="col">
                  <div class="row border-bottom border-1" v-for="station in department.stations" v-bind:key="station.id" v-bind:hidden="!checkedDepartments[department.id]">
                    <div class="form-check col-5 col-sm-2">
                      <input name="station" v-model="checkedStations[station.id]" id="station{{station.id}}" type="checkbox" value="{{station.shortName}}" class="form-check-input">
                      <label for="station{{station.id}}" class="form-check-label text-start w-100">{{ station.shortName }}</label>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="form-check col-20 col-sm-3" v-for="equipment in station.equipment" v-bind:key="equipment.id" v-bind:hidden="!checkedStations[station.id]">
                          <input name="equipment" v-model="checkedEquipment[equipment.id]" id="equipment{{equipment.id}}" type="checkbox" value="{{equipment.shortName}}" class="form-check-input">
                          <label for="equipment{{equipment.id}}" class="form-check-label text-start w-100">{{ equipment.shortName }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>


        <div class="row">
            <button type="submit"
                    class="btn btn-primary">
                Send It
            </button>
          <button type="reset" class="btn btn-danger mt-5" @click="clear">
            Clear
          </button>
        </div>
    </form>
  </section>
  <PagesList class="mt-4"/>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css';
import moment from "moment";
import PagesList from "@/views/PagesList.vue";

export default defineComponent({
  name: 'CallView',
  components: {PagesList, VueDatePicker},
  data() {
    return {
      form: {
        date: null,
        road:'',
        city:'',
        call_type:'',
        department:[],
        station:'',
        equipment:'',
      },
      checkedDepartments: [],
      checkedStations: [],
      checkedEquipment: [],
      departments: {
        1: {
          id: 1,
          shortName: 'CFD',
          stations: {
            1: {
              id: 1,
              shortName: 'STN1',
              equipment: {
                1: {
                  id: 1,
                  name: 'Engine 1',
                  shortName: 'E01'
                },
                2: {
                  id: 2,
                  name: 'Engine 2',
                  shortName: 'E02'
                },
                3: {
                  id: 3,
                  name: 'Engine 5',
                  shortName: 'E05'
                },
                4: {
                  id: 4,
                  name: 'Rescue 1',
                  shortName: 'R01'
                },
                5: {
                  id: 5,
                  name: 'Ladder 2',
                  shortName: 'A02'
                },
                6: {
                  id: 6,
                  name: 'Fast Rescue Craft 1',
                  shortName: 'FRC01'
                },
                7: {
                  id: 7,
                  name: 'Utility 1',
                  shortName: 'U01'
                },
                8: {
                  id: 8,
                  name: 'Fire Chief',
                  shortName: 'FC01'
                },
                9: {
                  id: 9,
                  name: 'Deputy Fire Chief',
                  shortName: 'DFC01'
                },
              }
            },
            2: {
              id: 2,
              shortName: 'STN2',
              equipment: {
                10: {
                  id: 10,
                  name: 'Engine 3',
                  shortName: 'E03'
                },
                11: {
                  id: 11,
                  name: 'Engine 4',
                  shortName: 'E04'
                },
                12: {
                  id: 12,
                  name: 'Engine 6',
                  shortName: 'E06'
                },
                13: {
                  id: 13,
                  name: 'Ladder 1',
                  shortName: 'A01'
                },
                14: {
                  id: 14,
                  name: 'Tanker 1',
                  shortName: 'T01'
                },
                15: {
                  id: 15,
                  name: 'Utility 2',
                  shortName: 'U02'
                },
                16: {
                  id: 16,
                  name: 'Off Road Rescue 1',
                  shortName: 'ORR01'
                },
              }
            }
          }
        },
        2: {
          id: 2,
          shortName: 'CRFD',
          stations: {
            3: {
              id: 3,
              shortName: '',
              equipment: {
                17: {
                  id: 17,
                  name: 'Engine 1',
                  shortName: 'E01'
                },
                18: {
                  id: 18,
                  name: 'Tanker 5',
                  shortName: 'T05'
                },
                19: {
                  id: 19,
                  name: 'Engine 6',
                  shortName: 'E06'
                },
                20: {
                  id: 20,
                  name: 'Rescue 11',
                  shortName: 'R11'
                },
                21: {
                  id: 21,
                  name: 'Tanker 2',
                  shortName: 'T02'
                },
                22: {
                  id: 22,
                  name: 'Tanker 4',
                  shortName: 'T04'
                },
                23: {
                  id: 23,
                  name: 'Tower 3',
                  shortName: 'A03'
                },
                24: {
                  id: 24,
                  name: 'Utility 7',
                  shortName: 'U07'
                },
                25: {
                  id: 25,
                  name: 'Fast Rescue Craft 1',
                  shortName: 'FRC01'
                },
                26: {
                  id: 26,
                  name: 'Off Road Rescue 1',
                  shortName: 'ORR01'
                },
              }
            }
          }
        },
        3: {
          id: 3,
          shortName: 'NRFD',
          stations: {
            4: {
              id: 4,
              shortName: '',
              equipment: {
                27: {
                  id: 27,
                  name: 'Engine 1',
                  shortName: 'E01'
                },
                28: {
                  id: 28,
                  name: 'Engine 3',
                  shortName: 'E03'
                },
                29: {
                  id: 29,
                  name: 'Engine 4',
                  shortName: 'E04'
                },
                30: {
                  id: 30,
                  name: 'Rescue 2',
                  shortName: 'R02'
                },
                31: {
                  id: 31,
                  name: 'Tanker 7',
                  shortName: 'T07'
                },
                32: {
                  id: 32,
                  name: 'Tower 6',
                  shortName: 'A06'
                },
                33: {
                  id: 33,
                  name: 'Utility 10',
                  shortName: 'U10'
                },
                34: {
                  id: 34,
                  name: 'Fast Rescue Craft 8',
                  shortName: 'FRC08'
                },
                35: {
                  id: 35,
                  name: 'Off Road Rescue 9',
                  shortName: 'ORR09'
                },
              }
            }
          }
        },
        4: {
          id: 4,
          shortName: 'NSFD',
          stations: {
            5: {
              id: 5,
              shortName: '',
              equipment: {
                36: {
                  id: 36,
                  name: 'Engine 1',
                  shortName: 'E01'
                },
                37: {
                  id: 37,
                  name: 'Engine 2',
                  shortName: 'E02'
                },
                38: {
                  id: 38,
                  name: 'Rescue 3',
                  shortName: 'R03'
                },
                39: {
                  id: 39,
                  name: 'Tanker 4',
                  shortName: 'T04'
                },
                40: {
                  id: 40,
                  name: 'Tanker 5',
                  shortName: 'T05'
                },
                41: {
                  id: 41,
                  name: 'Ladder 1',
                  shortName: 'A01'
                },
                42: {
                  id: 42,
                  name: 'Water Rescue Craft 1',
                  shortName: 'FRC01'
                },
              }
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['createCall']),
    async submit() {

      this.form.department = [];
      let stations = [];
      let equipment = [];

      for (let departmentsKey in this.departments) {
        if (this.checkedDepartments[departmentsKey]) {
           this.form.department.push(this.departments[departmentsKey].shortName);

           for (let stationKey in this.departments[departmentsKey].stations) {
             if (this.checkedStations[stationKey]) {
               stations.push(this.departments[departmentsKey].stations[stationKey].shortName);

               for (let equipmentKey in this.departments[departmentsKey].stations[stationKey].equipment) {
                 if (this.checkedEquipment[equipmentKey]) {
                    equipment.push(this.departments[departmentsKey].stations[stationKey].equipment[equipmentKey].shortName)
                 }
               }
             }
           }
        }
      }

      this.form.station = stations.join(' ');
      this.form.equipment = equipment.join(' ');

      const Call = new FormData();
      Call.append('date', moment(this.form.date).format('YYYY-MM-DD HH:mm'));
      Call.append('road', this.form.road);
      Call.append('city', this.form.city);
      Call.append('call_type', this.form.call_type);
      Call.append('department', this.form.department);
      Call.append('station', this.form.station);
      Call.append('equipment', this.form.equipment);
      console.log(Call);
      await this.createCall(Call);

      alert("posted")
    },
    clear() {
      this.form = {
        date: null,
        road:'',
        city:'',
        call_type:'',
        department:[],
        station:'',
        equipment:'',
      };

      this.checkedDepartments = [];
    }
  }
});
</script>