import { createStore } from "vuex";

import twittercall from './modules/twittercall';
import users from './modules/users';

export default createStore({
  modules: {
    twittercall,
    users,
  }
});
