import 'bootstrap/dist/css/bootstrap.css';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import store from './store';

const app = createApp(App);

axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'http://localhost:8000/';  // the FastAPI backend
axios.defaults.baseURL = 'https://api.fd.3centsmckenna.ca/';  // the FastAPI backend

app.use(router);
app.use(store);
app.mount('#app');
