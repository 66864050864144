<script>
import axios from "axios";
import moment from "moment/moment";

export default {
  name: "PagesList",
  computed: {
    moment() {
      return moment
    }
  },
  data() {
    return {
      pages: []
    }
  },
  methods: {
    getPages() {
      axios.get("/pages/getList")
          .then((response) => {
            this.pages = response.data
            console.log(this.pages)
          });
    }
  },
  mounted() {
    this.getPages();
  }
}
</script>

<template>
  <div class="list-group ">
    <div class="list-group-item d-flex justify-content-evenly">
      <span class="col-2 fw-bold border-end">Time</span>
      <span class="col-2 fw-bold border-end">Department</span>
      <span class="col fw-bold">Message</span>
    </div>
    <div class="list-group-item d-flex justify-content-evenly" v-for="page in pages" :key="page.id">
      <span class="col-2 border-end overflow-scroll">{{moment(page.created_at).format('YYYY-MM-DD HH:mm')}}</span>
      <span class="col-2 border-end">{{page.department.name}}</span>
      <span class="col ps-2 pe-2">{{page.message}}</span>
    </div>
  </div>
</template>

<style scoped>

</style>